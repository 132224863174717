import { Component } from '@mycolorway/tao';
import eventListenerMixin from 'next/javascripts/mixins/event-listener-mixin';
import 'next/stylesheets/components/tower-price-tab.scss';

Component('tr-tower-price-tab', {
  mixins: [eventListenerMixin],

  connected() {
    this.listenTo(this, 'click', this.handlePriceTabClick.bind(this));
  },

  handlePriceTabClick(e) {
    const item = e.target.classList.value;
    if (item.includes('tab-header-item')) {
      this.handleHeaderItemClick(e.target);
    }
  },

  handleHeaderItemClick(originItem) {
    const { value } = originItem.dataset;
    const tabHeader = this.querySelector('.tab-header');
    tabHeader.classList = `tab-header ${value}`;
    const tabContent = this.querySelector('.tab-content');
    tabContent.classList = `tab-content ${value}`;
  },
});
