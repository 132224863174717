import { Component } from '@mycolorway/tao';
import eventListenerMixin from 'next/javascripts/mixins/event-listener-mixin';

export default Component('tr-intercom-fallback', {
  mixins: [eventListenerMixin],

  properties: {
    active: Boolean,
    triggerSelector: {
      type: String,
      default: '#link-trigger-intercom',
    },
  },

  ready() {
    this.linkEl = this.querySelector('#link-to-help');
    this.cardEl = this.querySelector('.customer-service-card');

    const triggerEl = document.querySelector(this.triggerSelector);
    if (triggerEl) {
      this.listenTo(triggerEl, 'click', () => this.cardEl.classList.toggle('active'));
    }
    this.listenTo(this.linkEl, 'click', () => this.cardEl.classList.toggle('active'));
    // 暂时下线intercom未加载时，显示客服二维码功能（https://stage97.tower.im/teams/630713/todos/104646/）
    // this.startChecking();
  },

  disconnected() {
    this.stopChecking();
    this.stopListening();
  },

  startChecking() {
    this.checkTimer = setInterval(() => {
      if (this.intercomLoaded()) {
        this.stopChecking();
        this.active = false;
      } else {
        this.active = true;
      }
    }, 5000);
  },

  stopChecking() {
    if (this.checkTimer) {
      clearInterval(this.checkTimer);
      this.checkTimer = null;
    }
  },

  intercomLoaded() {
    return !!document.querySelector('iframe#intercom-frame');
  },
});
