
$(document).on 'turbolinks:load', ->
  $('.section-downloads .btn[data-tpl], .section-apps:not(.in-mobile) a[data-tpl]').on 'mouseenter', ->
    $btn = $(@)
    popover = simple.popover
      pointTo: $btn
      content: $("##{$btn.data('tpl')}").html()
      position: 'bottom-center',
      cls: 'popover-platform'
      autohide: !$btn.parent().is('.weapp')

    popover.el.css('opacity', 1)

    $btn.one 'mouseleave', ->
      popover.destroy()

  $('.section-apps.in-mobile .weapp a[data-tpl]').on 'click', (e) ->
    simple.popover.destroyAll()
    simple.dialog
      content: $("##{$(e.currentTarget).data('tpl')}").html()
      width: 300
      cls: 'dialog-header-wechat'
      buttons: false