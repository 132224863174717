$(document).on 'turbolinks:load', ->
  $('.signup-guide-form').on 'click', '.btn-signup', (e) ->
    $link = $ e.currentTarget
    $form = $link.closest '.signup-guide-form'
    $input = $form.find('input[type="email"]')
    email = $input.val()

    if email
      href = $link.attr('href').replace /\?.*$/, ''
      $link.attr 'href', "#{href}?email=#{email}"
      true
    else
      $input.focus()
      e.preventDefault()
      e.stopPropagation()

  $('.signup-guide-form input[type="email"]').on 'keydown', (e) ->
    $input = $ e.currentTarget
    email = $input.val()

    if e.which == 13 && email
      $link = $input.closest('.signup-guide-form').find '.btn-signup'
      href = $link.attr('href').replace /\?.*$/, ''

      location.href = "#{href}?email=#{email}"
