export default {
  init() {
    this.listeners = {};
  },

  listenTo(target, eventType, handler, options = {}) {
    if (!target) return;

    let events = {};

    if (Object.prototype.toString.call(eventType) === '[object Object]') {
      events = eventType;
      // eslint-disable-next-line no-param-reassign
      options = handler;
    } else {
      events[eventType] = handler;
    }

    Object.keys(events).forEach((evtType) => {
      const eventHandler = events[evtType];

      this.listeners[evtType] = this.listeners[evtType] || [];
      this.listeners[evtType].push({ target, handler: eventHandler, options });

      if (target.listen) {
        target.listen(evtType, eventHandler);
      } else {
        const args = [evtType, eventHandler];

        if (Object.prototype.toString.call(options) === '[object Object]') {
          if (Object.keys(options).length > 0) args.push(options);
        } else if (options) {
          args.push(options);
        }

        target.addEventListener(...args);
      }
    });
  },

  stopListening() {
    if (!this.listeners) return;

    Object.keys(this.listeners).forEach((eventType) => {
      this.listeners[eventType].forEach(({ target, handler, options }) => {
        if (!target) return;

        if (target.unlisten) {
          target.unlisten(eventType, handler);
        } else {
          const args = [eventType, handler];

          if (Object.prototype.toString.call(options) === '[object Object]') {
            if (Object.keys(options).length > 0) args.push(options);
          } else if (options) {
            args.push(options);
          }

          target.removeEventListener(...args);
        }
      });

      delete this.listeners[eventType];
    });
  },
};
