import { Component } from '@mycolorway/tao';
import Swiper from 'swiper';
import 'swiper/swiper';

Component('tr-tab-slider', {
  properties: {
    swiper: Object,
  },

  ready() {
    let thumbsOptions = {
      slidesPerView: 6,
      watchSlidesVisibility: true,
    };
    if (this.getAttribute('use-in-mobile') === 'true') {
      thumbsOptions = {
        slidesPerView: 3,
        slidesPerColumn: 2,
        watchSlidesVisibility: true,
      };
    }
    this.thumbsSwiper = new Swiper('.thumbs-swiper-container', thumbsOptions);
    this.gallerySwiper = new Swiper('.gallery-swiper-container', {
      autoplay: true,
      loop: true,
      speed: 900,
      thumbs: {
        swiper: this.thumbsSwiper,
      },
    });
  },

  disconnected() {
    if (this.thumbsSwiper) this.thumbsSwiper.destroy();
    if (this.gallerySwiper) this.gallerySwiper.destroy();
  },
});
