$window = $(window)

$(document).on 'turbolinks:before-cache', ->
  simple.popover.destroyAll()
  $window.off 'scroll'

$(document).on 'turbolinks:load', ->
  $header = $('.header')
  height = ($('[data-masthead]').outerHeight() || 0) + 0

  $window.on 'scroll', ->
    $header.toggleClass 'active', $window.scrollTop() > height

  $window.trigger 'scroll'
