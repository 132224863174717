require('next/javascripts/modules/turbolinks')
require('expose-loader?$!jquery')
require('expose-loader?jQuery!jquery')
require('jquery-ujs')
require('expose-loader?moment!moment')
require('expose-loader?PhotoSwipe!photoswipe')
require('expose-loader?PhotoSwipeUI_Default!photoswipe/dist/photoswipe-ui-default')
require('expose-loader?ScrollMagic!scrollmagic')

require('expose-loader?SimpleModule!simple-module')
require('expose-loader?simple.util!simple-util')
require('expose-loader?simple.url!simple-url')
require('expose-loader?simple.popover!simple-popover')
require('expose-loader?simple.dialog!simple-dialog')
require('expose-loader?simple.tooltip!simple-tooltip')
require('expose-loader?simple.scrollTo!simple-scrollTo')

require('./components')
require('./pages')
require('../components/inline_video')
require('../components/gallery')
require('../components/system-message')

require('next/javascripts/components/intercom-fallback');

require('tr_wework/javascripts/home')

require('next/javascripts/components/form-fields/text-field')
require('next/javascripts/components/dialog')
require('next/javascripts/components/scroll-magic')
require('next/javascripts/components/tab-slider')
require('next/javascripts/components/visitor-presales-support-widget')
require('next/javascripts/components/tower-price-tab')

require('next/javascripts/home/index_page')
require('next/javascripts/home/product')
require('next/javascripts/components/mini-slideshow')
require('next/javascripts/home/plans')
