import { Component } from '@mycolorway/tao';
import { MDCDialog } from '@material/dialog';
import { closest, matches } from 'next/javascripts/modules/dom';
import eventListnerMixin from 'next/javascripts/mixins/event-listener-mixin';
import 'next/stylesheets/components/dialog';

export default Component('tr-dialog', {
  mixins: [eventListnerMixin],

  properties: {
    openOnReady: {
      type: Boolean,
      default: true,
    },

    removeAfterClosed: {
      type: Boolean,
      default: false,
    },

    closeOnEscape: {
      type: Boolean,
      default: true,
    },
  },

  ready() {
    this.rootEl = this.querySelector('.mdc-dialog');
    this.mdcDialog = new MDCDialog(this.rootEl);
    this.mdcDialog.escapeKeyAction = '';

    this.listenTo(this.mdcDialog, {
      'MDCDialog:opened': () => {
        this.destroyAll();
        this.forceLayoutChildren();
        this.namespacedTrigger('opened');
      },

      'MDCDialog:closed': (event) => {
        if (this.removeAfterClosed) {
          this.parentNode.removeChild(this);
        }
        this.namespacedTrigger('closed', { detail: event.detail.action });
      },
    });

    this.listenTo(document, 'keydown', this.handleKeydown.bind(this));

    if (this.openOnReady) this.open();
  },

  forceLayoutChildren() {
    [...this.querySelectorAll('[tao-id]')].forEach((component) => {
      if (typeof component.layout === 'function') {
        if (component.taoStatus === 'ready') {
          component.layout.call(component);
        } else {
          component.addEventListener('tao:ready', component.layout.bind(component), {
            once: true,
          });
        }
      }
    });
  },

  open() {
    this.mdcDialog.open();
  },

  close(options = {}) {
    this.mdcDialog.close(options);
  },

  isOpen() {
    return this.mdcDialog.isOpen;
  },

  setTitle(title) {
    this.querySelector('.mdc-dialog__title').textContent = title;
  },

  setContent(content) {
    this.querySelector('.mdc-dialog__content').innerHTML = content;
  },

  destroyAll() {
    Array.from(document.querySelectorAll('tr-dialog'))
      .filter(dialog => !dialog.isSameNode(this)
        && dialog.taoStatus === 'ready'
        && dialog.isOpen()
        && !dialog.classList.contains('todo-form-dialog'))
      .forEach(dialog => dialog.parentNode.removeChild(dialog));
  },

  beforeCache() {
    if (this.mdcDialog) {
      this.close({ action: 'disconnected' });
    }
  },

  disconnected() {
    this.stopListening();
    if (this.mdcDialog) this.mdcDialog.destroy();
  },

  handleKeydown(event) {
    const isEscape = event.key === 'Escape' || event.keyCode === 27;
    if (!isEscape || !this.closeOnEscape) return;

    if (matches(event.target, 'input')
      || matches(event.target, 'textarea')
      || closest(event.target, 'form')
      || closest(event.target, '[contenteditable]')) return;

    if (this.isOpen()) {
      event.stopPropagation();
      event.preventDefault();
      this.close({ action: 'no' });
    }
  },
});

function renderDialog(opts) {
  const buttons = opts.buttons
    .map(
      btn => `<button data-mdc-dialog-action="${btn.action}"
                class="tr-button mdc-button mdc-dialog__button mdc-button--dense ${btn.cls}">
                <span class="mdc-button__label">${btn.text}</span>
              </button>`,
    )
    .join('');

  const dialog = document.createElement('tr-dialog');
  dialog.openOnReady = true;
  dialog.removeAfterClosed = true;
  dialog.innerHTML = `
    <div class="mdc-dialog">
      <div class="mdc-dialog__container">
        <div class="mdc-dialog__surface">
          ${opts.title ? `<h2 class="mdc-dialog__title">${opts.title}</h2>` : ''}
          <div class="mdc-dialog__content">${opts.content}</div>
          <footer class="mdc-dialog__actions">
            ${buttons}
          </footer>
        </div>
      </div>
      ${opts.modal ? '<div class="mdc-dialog__scrim"></div>' : ''}
    </div>`;
  document.body.appendChild(dialog);
  return dialog;
}

export function showMessage(opts) {
  return renderDialog(
    Object.assign(
      {
        title: '',
        content: '',
        modal: false,
        buttons: [
          {
            text: '确认',
            action: 'yes',
          },
        ],
      },
      opts,
    ),
  );
}

export function showConfirmation(opts) {
  return renderDialog(
    Object.assign(
      {
        title: '',
        content: '',
        modal: false,
        buttons: [
          {
            text: '确认',
            action: 'yes',
          },
          {
            text: '取消',
            action: 'no',
            cls: 'button-text',
          },
        ],
      },
      opts,
    ),
  );
}
