import { Component } from '@mycolorway/tao';
import BaseMixin from './base';

import 'next/stylesheets/components/scroll-magic/items/toggle-class.scss';

Component('tr-scroll-magic-toggle-class', {
  mixins: [BaseMixin],

  properties: {
    className: String,
  },

  defaultConfig() {
    return {
      triggerElement: this,
      triggerHook: 0.7,
    };
  },

  ready() {
    [this.slideTarget] = this.children;
    this.slideTarget.classList.add(this.className);
    this.scene.on('start', () => {
      this.slideTarget.classList.add('transition-active');
    }).on('end', () => {
      this.slideTarget.classList.remove('transition-active');
    });
  },
});
