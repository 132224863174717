$ ->
  $(document).on 'click', '.inline-video-controls .inline-video-button-replay', ->
    $inlineVideo = $(@).closest('.inline-video-controls').siblings('.inline-video-media')
    playInlineVideo($inlineVideo)


  $(document).on 'ended', '.inline-video-media .inline-video', ->
    $(@).closest('.inline-video-media').siblings('.inline-video-controls').show()

  autoPlayInlineVideo()

  $(window).on 'scroll', ->
      autoPlayInlineVideo()

autoPlayInlineVideo = ->
  $('.inline-video-media').each ->
    if $(@).isOnScreen() and !$(@).hasClass('autoplayed')
      $(@).addClass('autoplayed')
      playInlineVideo($(@))

playInlineVideo = ($inlineVideo) ->
  videoEl = $inlineVideo.find('.inline-video').get(0)

  if videoEl
    videoEl.play()
    videoEl.onended = ->
      $(@).closest('.inline-video-media').siblings('.inline-video-controls').show()
    $inlineVideo.siblings('.inline-video-controls').hide()

$.fn.isOnScreen = ->
  win = $(window)
  viewport =
    top: win.scrollTop()
    left: win.scrollLeft()

  viewport.right = viewport.left + win.width()
  viewport.bottom = viewport.top + win.height()
  bounds = @offset()
  bounds.right = bounds.left + @outerWidth()
  bounds.bottom = bounds.top + @outerHeight()

  return !(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom)
