document.addEventListener('turbolinks:load', () => {
  const table = document.querySelector('#home-plans .tower-price-table');
  if (table) {
    document.addEventListener('scroll', tableScroll);
  }
});

document.addEventListener('turbolinks:before-visit', () => {
  if (document.querySelector('#home-plans .tower-price-table')) {
    document.removeEventListener('scroll', tableScroll);
  }
});

window.addEventListener('beforeunload', () => {
  if (document.querySelector('#home-plans .tower-price-table')) {
    document.removeEventListener('scroll', tableScroll);
  }
});

function tableScroll() {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  const header = document.querySelector('#home-plans .table-header');
  const trickHeader = document.querySelector('#home-plans .trick-header');
  if (!header || !trickHeader) return;
  if (scrollTop >= 736 && scrollTop <= 2800) {
    header.classList.add('active');
    trickHeader.classList.add('active');
  } else {
    header.classList.remove('active');
    trickHeader.classList.remove('active');
  }
}
