$ ->
  window.tower ||= {}

  $(document).on 'click', '*[data-image-src], .editor-style img:not([data-non-image], .teditor-emoji)', (e) ->
    $img = $(@)
    return unless src = $img.attr('data-image-src') || $img.attr('src')
    src = formatImageUrl src
    metaKey = if tower.metaKey then tower.metaKey(e) else false

    if metaKey
      window.open src
    else
      initPhotoSwipe($img, src)
    return false

  tower.stack?.on 'pageunload', (e) ->
    gallery = $('.pswp').data('gallery')
    gallery.close() if gallery
    return e.result


initPhotoSwipe = ($img, src) ->
  $pswp = $('.pswp')
  $wrap = $img.closest('.gallery-wrap')

  if $wrap.length
    items = $wrap.find('img:not([data-non-image], .teditor-emoji)').map (i, img) ->
      return {
        src: formatImageUrl(img.src)
        w: 0
        h: 0
        title: img.alt
      }
    .get()
  else
    items = [{
      src: src
      w: 0
      h: 0
    }]

  options =
    index: findWithAttr(items, 'src', src)
    history: false
    mouseUsed: true
    closeOnScroll: false
    showHideOpacity: true
    captionEl: true
    shareEl: false
    bgOpacity: 0.85
    errorMsg: '<div class="pswp__error-msg"><a href="%url%" target="_blank">这张图片</a> 无法加载。</div>'
    getDoubleTapZoom: (isMouseClick, item) ->
      item.zoomLevel = item.initialZoomLevel unless item.zoomLevel
      if item.zoomLevel < 0.4
        item.zoomLevel = 0.4
      else if item.zoomLevel < 0.7
        item.zoomLevel = 0.7
      else if item.zoomLevel < 1
        item.zoomLevel = 1
      else
        item.zoomLevel = item.initialZoomLevel
      # item.zoomLevel = level
      document.querySelector('.pswp').dataset.zoomLevel = item.zoomLevel
      return item.zoomLevel

  gallery = new PhotoSwipe($pswp[0], PhotoSwipeUI_Default, items, options)

  gallery.listen 'gettingData', (index, item) ->
    return if item.w > 1 && item.h > 1
    img = new Image
    img.onload = ->
      item.w = @width
      item.h = @height
      gallery.invalidateCurrItems()
      gallery.updateSize true
    img.src = item.src

  # init rotate & download
  $download = $pswp.find('.pswp__button--download')
  $newtab = $pswp.find('.pswp__button--newtab')
  $rotate = $pswp.find('.pswp__button--rotate')

  gallery.listen 'beforeChange', ->
    $pswp.find('.pswp__img').data('rotate', 0).css('transform', 'rotate(0)')

  gallery.listen 'afterChange', ->
    $download.attr 'href', formatDownloadUrl(@currItem.src)
    $newtab.attr 'href', @currItem.src
    $pswp.attr('data-zoom-level', null)

    $img = $(@currItem.container).find('.pswp__img')
    $rotate.off('click').on 'click', =>
      rotate = $img.data('rotate') - 90
      $img.data('rotate', rotate).css('transform', "rotate(#{rotate}deg)")

  gallery.listen 'close', ->
    $download.attr('href', '')
    $newtab.attr('href', '')
    $pswp.attr('data-zoom-level', null)
    $pswp.data('gallery', null)

  $pswp.data('gallery', gallery)
  gallery.init()

formatImageUrl = (src) ->
  url = simple.url(src)
  delete url.search.version if src.indexOf(process.env.FILE_CENTER_URI_BASE) > -1
  return url.toString('absolute').replace(/\/$/, '')

formatDownloadUrl = (src) ->
  url = simple.url(src)
  if src.indexOf(process.env.FILE_CENTER_URI_BASE) > -1
    url.setParam('download', true)
    return url.toString('absolute').replace(/\/$/, '')
  else
    return ''

findWithAttr = (array, attr, value) ->
  i = 0
  while i < array.length
    return i if array[i][attr] == value
    i += 1
  -1
