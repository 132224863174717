import ScrollMagic from 'scrollmagic';

export default {
  properties: {
    config: Object,
  },

  defaultConfig() {
  },

  ready() {
    this.scene = new ScrollMagic.Scene(Object.assign({}, this.defaultConfig(), this.config));
    this.classList.add('tr-scroll-magic-item');
  },

  disconnected() {
    if (this.scene) this.scene.destroy();
    this.scene = null;
  },

};
