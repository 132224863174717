import { ponyfill } from '@material/dom';

const { matches } = ponyfill;

function closest(element, selector, context = document) {
  let el = element;

  while (el && el !== context) {
    if (matches(el, selector)) return el;
    el = el.parentElement;
  }

  return null;
}

export { matches, closest };
