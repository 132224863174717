import { Component } from '@mycolorway/tao';
import ScrollMagic from 'scrollmagic';

import 'next/stylesheets/components/scroll-magic/element.scss';

Component('tr-scroll-magic', {

  properties: {
    config: Object,
  },

  ready() {
    this.controller = new ScrollMagic.Controller(this.config || {});

    [...this.querySelectorAll('.tr-scroll-magic-item')].forEach((item) => {
      this.controller.addScene(item.scene);
    });
  },

  disconnected() {
    if (this.controller) this.controller.destroy();
    this.controller = null;
  },

  enable() {
    this.controller.enabled(true);
  },

  disable() {
    this.controller.enable(false);
  },

});
