import Turbolinks from 'turbolinks';

document.addEventListener('turbolinks:request-start', (event) => {
  if (process.env.IS_CANARY_UPSTREAM) {
    const { xhr } = event.data;
    xhr.setRequestHeader('Twr-Canary-Host', process.env.HOST);
  }
});

Turbolinks.start();
window.Turbolinks = Turbolinks;

export default Turbolinks;
