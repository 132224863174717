import 'next/stylesheets/home/product.scss';

document.addEventListener('turbolinks:load', () => {
  const videoLink = document.querySelector('#home-product #link-video');
  const dialog = document.querySelector('#home-product .product-video-dialog');

  if (videoLink && dialog) {
    const video = dialog.querySelector('video');
    if (video) {
      videoLink.addEventListener('click', () => {
        dialog.open();
        video.play();
      });
    }
  }

  if (dialog) {
    const video = dialog.querySelector('video');
    if (video) {
      dialog.addEventListener('tr-dialog:closed', () => {
        video.pause();
      });
    }
  }
});
