# 初始化所有的tooltip
$(document).on 'mouseenter', '[data-tooltip]', (e) ->
  $('[data-tooltip]').each ->
    tooltip = $(@).data('simple-tooltip')
    if tooltip then tooltip.hide() else $('.simple-tooltip').remove()
  $target = $(@)
  tooltip = simple.tooltip
    el: $target
    content: $target.attr 'data-tooltip'
    position: $target.attr('data-position') or 'auto'
  tooltip.show()
.on 'mouseleave', '[data-tooltip]', (e) ->
  tooltip = $(@).data('simple-tooltip')
  if tooltip then tooltip.hide() else $('.simple-tooltip').remove()
