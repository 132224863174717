import { Component } from '@mycolorway/tao';
import Swiper from 'swiper';
import 'swiper/swiper';

Component('tr-mini-slideshow', {
  properties: {
    swiper: Object,
  },

  connected() {
  },

  ready() {
    const slidesCount = this.querySelectorAll('.swiper-slide').length;
    let options = {};
    if (slidesCount <= 1) {
      options = {
        loop: false,
        autoplay: false,
        width: 1152,
      };
    } else {
      options = {
        loop: true,
        speed: 900,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        spaceBetween: -400,
        grabCursor: true,
      };
    }
    this.swiper = new Swiper('.swiper-container', options);
  },

  disconnected() {
    if (this.swiper) this.swiper.destroy();
  },
});
