import { Component } from '@mycolorway/tao';
import { MDCTextField, MDCTextFieldHelperText } from '@material/textfield';
import eventListenerMixin from 'next/javascripts/mixins/event-listener-mixin';

export default Component('tr-text-field', {
  mixins: [eventListenerMixin],
  properties: {
    disabled: {
      type: Boolean,
      default: false,
      observer() {
        this.textField.disabled = this.disabled;
      },
    },
  },

  connected() {
    this.nativeField = this.querySelector('.mdc-text-field__input');
    this.textField = new MDCTextField(this.querySelector('.mdc-text-field'));
    this.helperText = new MDCTextFieldHelperText(this.querySelector('.mdc-text-field-helper-text'));
    this.listenTo(this.textField, 'change', () => { this.notifyChange(); });
  },

  disconnected() {
    if (this.textField) this.textField.destroy();
    if (this.helperText) this.helperText.destroy();
  },

  reset() {
    this.textField.value = '';
  },

  layout() {
    this.textField.layout();
  },

  setValue(value) {
    this.textField.value = value;
  },

  getValue() {
    return this.textField.value;
  },

  focus() {
    this.textField.focus();
  },

  notifyChange() {
    this.namespacedTrigger('change', {
      detail: this.getValue(),
      bubbles: true,
    });
  },
});
