import { Component } from '@mycolorway/tao';
import eventListenerMixin from 'next/javascripts/mixins/event-listener-mixin';
import visitorPresalesSupportWidget from './presales-support-widget';
import 'next/stylesheets/components/presales-support-widget';

Component('tr-visitor-presales-support-widget', {
  extends: [visitorPresalesSupportWidget],
  mixins: [eventListenerMixin],

  disconnected() {
    this.stopListening();
  },
});
