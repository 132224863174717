import { Component } from '@mycolorway/tao';
import BaseMixin from './base';

import 'next/stylesheets/components/scroll-magic/items/move.scss';

Component('tr-scroll-magic-move', {
  mixins: [BaseMixin],

  properties: {
    axis: {
      type: String,
      default: 'y',
    },
    startXPosition: {
      type: Number,
      default: 0,
    },
    startYPosition: {
      type: Number,
      default: 0,
    },
    endXPosition: {
      type: Number,
      default: 0,
    },
    endYPosition: {
      type: Number,
      default: 0,
    },
  },

  defaultConfig() {
    return {
      duration: '110%',
      triggerElement: this.parentNode,
      triggerHook: 1,
      offset: 200,
    };
  },

  ready() {
    this.moveDistance = {
      x: this.endXPosition - this.startXPosition,
      y: this.endYPosition - this.startYPosition,
    };
    this.setPosition();

    this.scene.on('progress', (e) => {
      this.setPosition(e.progress);
    });
  },

  setPosition(progress = 0) {
    window.requestAnimationFrame(() => {
      if (this.axis === 'both' || this.axis === 'y') {
        this.style.top = `${(this.startYPosition + this.moveDistance.y * progress).toFixed()}px`;
      }
      if (this.axis === 'both' || this.axis === 'x') {
        this.style.left = `${(this.startXPosition + this.moveDistance.x * progress).toFixed()}px`;
      }
    });
  },
});
