import { Component } from '@mycolorway/tao';
import BaseMixin from './base';

import 'next/stylesheets/components/scroll-magic/items/video.scss';

Component('tr-scroll-magic-video', {
  mixins: [BaseMixin],

  defaultConfig() {
    return {
      duration: '100%',
      triggerElement: this,
      triggerHook: 1,
      offset: this.offsetHeight / 2,
    };
  },

  ready() {
    this.video = this.querySelector('video');
    this.scene.on('enter', this.playVideo.bind(this))
      .on('leave', this.pauseVideo.bind(this));
  },

  playVideo() {
    this.video.play();
  },

  pauseVideo() {
    this.video.pause();
  },
});
