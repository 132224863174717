$ ->
  $(document).on 'click', '.system-message .btn-close', (e) =>
    $(e.target).closest('.system-message').remove()

    bannerId = $(e.target).attr('banner-id')
    if bannerId
      name = "close_banner_#{bannerId}"
      date = new Date()
      date.setTime(date.getTime() + (100*24*60*60*1000))
      document.cookie = "#{name}=true; max-age=#{100*24*60*60}; expires=#{date.toUTCString()}; path=/"

