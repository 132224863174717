page = 'home-customers'

$(document).on 'turbolinks:load', ->
  $items = $("##{page} .customer-item")

  $("##{page} .link-category").on 'click', ->
    $link = $(@)
    text = $link.text()
    $link.addClass('active').siblings('.active').removeClass('active')
    $items.hide()

    if text == '全部'
      $items.css('display', 'flex')
    else
      $items.filter("[data-category='#{text}']").css('display', 'flex')

  $('.link-category.active').trigger 'click'
